// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("bootstrap")

require("trix")
require("@rails/actiontext")

// https://github.com/nathanvda/cocoon/issues/555#issuecomment-640256856
require("cocoon")

require("select2")
require("select2/dist/js/i18n/pt-BR")

import "../stylesheets/application";

import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

import "controllers";

function applySelect2(element) {
  element.select2({
    theme: 'bootstrap4',
    language: "pt-BR",
  });
}

// ref: https://www.michielsikkes.com/remove-flickering-flash-messages-on-turbolinks/
document.addEventListener("turbolinks:before-cache", function () {
  // Tear down modal instances between Turbolinks pages
  const modalElement = document.querySelector("[data-modal]")
  if (modalElement) {
    modalElement.remove()
  }

  // Tear down flashes instances between Turbolinks pages
  const flashMessageElement = document.querySelector("[data-flash]")
  if (flashMessageElement) {
    flashMessageElement.remove()
  }

  // Tear down select2 instances between Turbolinks pages
  $("select").select2('destroy');

  // Tear down apexcharts svg canvas between Turbolinks pages
  $(".apexcharts-canvas").remove();
});

document.addEventListener("turbolinks:load", function () {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
  })

  applySelect2($("select"));

  $(".cities-data-ajax").select2({
    theme: 'bootstrap4',
    language: "pt-BR",
    ajax: {
      url: "/cities",
      dataType: 'json',
      delay: 250,
      data: function (params) {

        return {
          q: params.term, // search term
        };
      },
      processResults: function (data, params) {
        return {
          results: data
        };
      },
    },
    placeholder: 'Busque por uma cidade',
    minimumInputLength: 3,
  });

  $(document).on('change', '.custom-file-input', function (event) {
    var file = event.target.files[0],
        labelInput = $(this).next('.custom-file-label'),
        text = labelInput.data("text");

    if(file && file.name) text = file.name;

    $(labelInput).text(text);
  })

  $(document).on("ajax:success", function (event) {
    let container = event.target.closest(".attachment-container");

    container.classList.add("attachment-container-removed");

    container.onanimationend = (e) => {
      container.remove();
    };
  });

  const reportForm = document.getElementById('download-operation-center-reports-form');

  $(reportForm).on("submit", function(){
    setTimeout(resetReportFormButton, 1000);
  });

  function resetReportFormButton() {
    let submitButton = document.getElementById('download-operation-center-reports-submit');

    submitButton.value = submitButton.dataset.enableWith;
    submitButton.removeAttribute("disabled");
  }
});


document.addEventListener("trix-file-accept", function (e) {
  e.preventDefault();
});

$(document).on('cocoon:after-insert', function (e, insertedItem) {
  applySelect2($(insertedItem).find("select"));
});
